@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&family=VT323&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Mono&display=swap');

:root {
  --dark: #606c38;
  --medium: #97AF46 ;
  --light: #DAEAA5;
  --neutral: #BD9C5F;
  --white: #fefae0;
}

html, body {
  /* background-color: var(--dark); */
  background-image: url('../images/background.jpg') ;
}


header {
  display: flex;
  justify-content: flex-start;
  background-color: var(--dark);
  height: 80px;
}

header h1 {
  font-size: 40px;
  font-family: "Press Start 2P";
  margin-left: 20px;
  padding-top: 20px;
  color: var(--light);
  text-shadow: 5px 2px var(--medium);

}

main h1 {
  font-size: 30px;
  font-family: "Press Start 2P";
  padding-top: 20px;
  padding-bottom: 20px;
  color: var(--dark);
  text-align: center;
  padding-bottom: 20px;
}

nav { 
  position: absolute;
  top: 35px;
  right: 25px;
}

.badge img {
  width: 100px;
  height: auto;
}

main { 
  margin-top: 0.25%;
  border-radius: 37.5px;
  border: 3.75px solid var(--dark);
  padding-left: 5%;
  padding-right: 5%;
  width: 85%;
  margin: 0 auto;
  margin-left: 7.5%;
  margin-top: 50px;
  background-color: var(--white);

}


form { 
  padding-top: 25px;
  font-family: 'Red Hat Mono', monospace;
  font-size: 18px;
}

label {
  width: 10%;
  font-weight: bold;
  font-style: oblique;
}

input, textarea {
  width: 80%;
}


.nav1 {
  display: flex;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav1-link {
  display: block;
  padding: 0.5rem 1rem;
  color: whitesmoke;
  text-decoration: none;
}

.nav1-link:hover {
    color: var(--light);
    text-decoration: none;
  }

.nav1-item {
  margin-bottom: 0.5rem;
}

.nav1-link.active {
  color: var(--dark);
  background-color: var(--light);
  text-decoration: none;
  border-radius: 8px;
}

.nav1-link.active:hover {
  color: var(--white);

}

.text {
  font-family: 'Red Hat Mono', monospace;
  text-align: center;
  font-size: 18px;
}

.text2 {
  font-family: 'Red Hat Mono', monospace;
  font-size: 18px;
}

.resume {
  display: flex;
  justify-content: space-between;
  padding-top: 25px;
}

.badge {
  margin-bottom: 15px;
  width: auto;
 
}

.portfolio img, .featuredProject img {
  width: 100%;
  height: 200px;
}


.badgeLabel {
  color: black;
  font-family: 'Red Hat Mono', monospace;
  text-align: center;
  font-size: 20px;
  font-weight: 900;
  margin-top: 10px;
}

#self {
  width: 90%;
  height: auto;
  margin-bottom: 20px;
  margin-left: 10%;
  padding-right: 5px;
}

.projectBox {
  height: 350px;
  background-color: var(--light);
  margin-bottom: 15px;
  
}

.featuredProject {
  height: 420px;
  background-color: var(--light);
  margin-bottom: 15px;
  padding-right: 5px;
}

.info-box {
  background-color: var(--light);
  color: var(--dark);
  padding-left: 10px;
  padding-top: 10px;
  font-family: 'Red Hat Mono', monospace;
}

.info-box h3 {
  font-style: oblique;
  font-weight: 900;
}



.projectBox a, .featuredProject a {
  text-decoration: none;
}

.projectBox:hover, .featuredProject:hover {
  border: 5px solid var(--white);
  opacity: 0.5;
}

footer {
  height: 60px;
  margin-top: 2.5%;
  background-color: var(--dark);
  display: flex;
  justify-content: center;
  padding-right: 25px;
  padding-top: 10px;
  font-family: 'Red Hat Mono', monospace;
  font-size: 20px;
}

footer p {
  color: var(--medium);
  font-family: "Press Start 2P";
  padding-top: 10px;

}

footer img {
  height: 85%;
  width: auto;
}

footer a {
  margin: 0 25px;
}

footer a:hover {
  opacity: 0.5;
}


@media screen and (max-width: 960px) { 

  header{
    flex-direction: column;
    justify-content:flex-start;
    align-items: center;
    height: 120px;
  }
  header h1 {
    font-size: 32px;
    text-align: center;
    margin-left: 0px;
  }
  footer p {
    font-size: 14px;
    text-align: center;
  }
  nav { 
    position: relative;
    top: 0px;
    right: 0px;
  }
  .projectBox {
   margin-top: 20px;
  margin-bottom: 30px;
  }
  .featuredProject {
    height: 450px;
  }
}

@media screen and (max-width: 768px) { 
  header h1 {
    font-size: 30px;
  }
  .featuredProject {
    height: 520px;
  }
}